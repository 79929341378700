import { useCallback } from 'react';
import { sortContractorsUtility } from '../Helpers/sortContractors';
import { weeklyScheduleApi } from 'src/Api/weekly-schedule';

const useContractors = () => {
    const updateContractorHistory = useCallback( async (siteId: number | string | {value: number | string} | (number | string | {value: number | string})[]) => {
        // If we get an array, update the history for each element
        if (Array.isArray(siteId)) {
            siteId.map((id) => {
                updateContractorHistory(id);
            });
            return;
        }

        // Type guard to check if siteId is an object with 'value'
        const hasValueProperty = (obj: any): obj is { value: number | string } => {
            return typeof obj === "object" && obj !== null && "value" in obj;
        };
        if (hasValueProperty(siteId)) { siteId = siteId.value; }

        if (typeof siteId !== "number" && typeof siteId !== "string") return;

        // load the existing history from local storage
        const contractorSiteHistory = JSON.parse(localStorage.getItem("contractorSiteHistory") || "[]");
        const siteIdStr = siteId.toString();

        // remove the siteId from the history if it already exists
        const index = contractorSiteHistory.indexOf(siteIdStr);
        if (index !== -1) {
            contractorSiteHistory.splice(index, 1);
        }

        // add the siteId to the front of the history then save it
        contractorSiteHistory.unshift(siteIdStr);
        localStorage.setItem("contractorSiteHistory", JSON.stringify(contractorSiteHistory));

        // Make the API call to update the contractor position
        try {
            await weeklyScheduleApi.updateContractorOrder({ contractor_id: siteIdStr });
        } catch (error) {
            console.error("Failed to update contractor position on the server:", error);
            // Optionally handle errors (e.g., rollback local storage updates)
        }
    }, []);

    // unlikely to be needed, because sorting can be done once in the API call
    // comes from urils/Helpers/sort.ts so you can likely use that directly instead
    // this is just a link to that function in case someone wants to sort in the hook
    const sortContractors = useCallback((input) => {
        sortContractorsUtility(input);
    }, []);

    return { updateContractorHistory, sortContractors };
};

export default useContractors;
