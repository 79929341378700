export const storeDisplayOrders = (displayOrderData: any, userId: number | string | undefined) => {
    //console.log("storeDisplayOrders");
    //console.log(userId);

    if (!userId) {
        console.error("User ID is not available. Cannot process display order data.");
        return;
    }

    // Find the user's display order record
    const userDisplayOrderRecord = displayOrderData.find((record: any) => record.user_id === userId);
    //console.log(userDisplayOrderRecord);

    if (!userDisplayOrderRecord) {
        console.warn(`No display order data found for user ID ${userId}.`);
        return;
    }

    // Parse the list_user_position field (handles both old and new formats)
    const listUserPosition = JSON.parse(userDisplayOrderRecord.list_user_position || "{}");
    const contractorOrder = Array.isArray(listUserPosition.contractorOrder)
        ? listUserPosition.contractorOrder
        : []; // Fallback to empty array if not present or improperly formatted

    // Update local storage with contractor order
    localStorage.setItem("contractorSiteHistory", JSON.stringify(contractorOrder));
};
